import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar() {
    return (
        <nav className="navbar fixed-top navbar-expand-md navbar-dark">

            <div className="container-fluid">

                <Link className="navbar-brand" to="/app/home">
                    <img src="/Images/softbridge_semFundo.png" alt="" height="30" />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/app/home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/app/novoCliente">Novo Cliente</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="#">Sair</Link>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    );
}

export default Navbar;