import React from 'react';
import { Link } from 'react-router-dom';

function Banner(){
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>Uma plataforma de chamados simples de configurar e fácil de usar.</h1>
                    <h3>Gerencie seus chamados em um único lugar.</h3>
                    <h5>Mas a abertura do chamado pode ser realizada em qualquer ambiente, seu cliente escolhe!</h5>
                    
                    <Link to={'/app'} type="button" className="btn btn-outline-dark btn-lg btn-banner">Fazer Login</Link>
                </div>

                <div className="col-lg-6">
                    <img src="Images/Tela_desktop.png" />
                </div>
       
            </div>
        </div>
    </section>;
  }

export default Banner;