// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    height: 100vh;
    background-color: darkgray;
}

.form-login input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
}

.form-login input[type="senha"] {
    margin-bottom: 10px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
}

.form-login {
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.login-links a{
    text-decoration: none;
    font-size: 10pt;
}`, "",{"version":3,"sources":["webpack://./src/App/Login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".form-container {\r\n    height: 100vh;\r\n    background-color: darkgray;\r\n}\r\n\r\n.form-login input[type=\"email\"] {\r\n    margin-bottom: -1px;\r\n    border-bottom-right-radius: 0;\r\n    border-top-left-radius: 0;\r\n}\r\n\r\n.form-login input[type=\"senha\"] {\r\n    margin-bottom: 10px;\r\n    border-bottom-right-radius: 0;\r\n    border-top-left-radius: 0;\r\n}\r\n\r\n.form-login {\r\n    max-width: 330px;\r\n    padding: 15px;\r\n    margin: auto;\r\n}\r\n\r\n.login-links a{\r\n    text-decoration: none;\r\n    font-size: 10pt;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
