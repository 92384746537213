import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Site from './Site/site.jsx';
import Login from './App/Login/login.jsx';
import NovaConta from './App/NovaConta/novaConta.jsx';
import ReseteSenha from './App/ResetarSenha/resetarSenha.jsx';
import Home from './App/Home/home.jsx';
import NovoCliente from './App/NovoCliente/novoCliente.jsx';

function App() {
    return <BrowserRouter>
        <Routes>
            <Route exact path='/' Component={Site} />
            <Route exact path='/app' Component={Login} />
            <Route exact path='/app/novaConta' Component={NovaConta}/>
            <Route exact path='/app/reseteSenha' Component={ReseteSenha}/>
            <Route exact path='/app/home' Component={Home}/>
            <Route exact path='/app/novoCliente' Component={NovoCliente}/>
        </Routes>
    </BrowserRouter>;
}

export default App;