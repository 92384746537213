// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NAVBAR */

.navbar{
    background-color: darkgrey;
}`, "",{"version":3,"sources":["webpack://./src/App/Components/Navbar/navbar.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;IACI,0BAA0B;AAC9B","sourcesContent":["/* NAVBAR */\r\n\r\n.navbar{\r\n    background-color: darkgrey;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
