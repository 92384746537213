import React from 'react';
import Navbar from '../Components/Navbar/navbar';



import './home.css';

function Home() {
    return (
            <Navbar/>
    );
}

export default Home;