import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import firebase from '../Config/firebase';
import 'firebase/compat/auth';

import './login.css';

function Login() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');

    function loginUsuario() {
        firebase.auth().signInWithEmailAndPassword(email, senha)
            .then(function (firebaseUser) {
                indoParaHome();
                setSucesso('S');
            })
            .catch(function (error) {
                setSucesso('N');
            });
    }

    function alterarEmail(event) {
        setEmail(event.target.value);
    }

    function alterarSenha(event) {
        setSenha(event.target.value);
    }

    const indoParaHome = () => {
        navigate('/app/home');
    };

    /**
     * Visto a necessidade inicial de não criar conta avulso, foi necessário retirar a opção
     * <Link to={'/app/novaconta'} className='mx-3 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Criar uma conta</Link>
     */

    return <div className='d-flex align-items-center text-center form-container' >
        <form className='form-login'>
            <Link to='/'><img className="mb-4" src="/Images/softbridge_semFundo.png" alt="" width="300px" /></Link>
            <h1 className="h3 mb-3 fw-normal">Login</h1>

            <div className="form-floating">
                <input onChange={alterarEmail} type="email" className="form-control" id="floatingInput" placeholder="email" />
                <label for="floatingInput">E-mail</label>
            </div>
            <p></p>
            <div className="form-floating">
                <input onChange={alterarSenha} type="password" className="form-control" id="floatingPassword" placeholder="senha" />
                <label for="floatingPassword">Senha</label>
            </div>
            <p></p>
            <button onClick={loginUsuario} className="btn btn-secondary w-100 py-2" type="button">Entrar</button>

            {sucesso === 'N' ? <div className='alert alert-danger mt-2' role='alert'>E-mail ou senha inválidos.</div> : ''}

            <div className='login-links mt-5'>
                <Link to={'/app/ReseteSenha'} className='mx-3 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Esqueci minha senha</Link>
            </div>
            <p className="mt-5 mb-3 text-body-secondary">&copy; Desenvolvido por Softbridge - 2024</p>
        </form>
    </div>;
}

export default Login;