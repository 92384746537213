import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './novaConta.css';

//import firebase from '../Config/firebase';
import 'firebase/compat/auth';

function NovaConta() {

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [mensagem, setMensagem] = useState('');
    
    function cadastrarUsuario() {
        setMensagem('');

        if(email.trim().length === 0){
            setMensagem('Informe um e-mail !');
            return;
        }

        if(senha.trim().length === 0){
            setMensagem('Informe uma senha!');
            return;
        }
        /**
         * Retirado pois o usuário não deverá cadastrar manualmente as informações, deve passar sempre pela empresa antes
        firebase.auth().createUserWithEmailAndPassword(email,senha)
        .then(resultado => {
            alert('Usuário criado com sucesso!')
        })
        .catch(erro => {
            setMensagem(erro.message);
        })
        */
    }

    return <div className='d-flex align-items-center text-center form-container' >
        <form className='form-login'>
            <Link to='/app'><img className="mb-4" src="/Images/softbridge_semFundo.png" alt="" width="300px" /></Link>
            <h1 className="h3 mb-3 fw-normal">Criar Conta</h1>

            <div className="form-floating">
                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="floatingInput" placeholder="email" />
                <label for="floatingInput">E-mail</label>
            </div>
            <p></p>
            <div className="form-floating">
                <input onChange={(e) => setSenha(e.target.value)} type="password" className="form-control" id="floatingPassword" placeholder="senha" />
                <label for="floatingPassword">Senha</label>
            </div>
            <p></p>
            <button onClick={cadastrarUsuario} className="btn btn-secondary w-100 py-2" type="button">Criar Conta</button>

            {mensagem.length > 0 ? <div className='alert alert-danger mt-2' role='alert'>{mensagem}</div> : ''}
            <div className='login-links mt-5'>
                <Link to={"/app"} className='mx-3 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Já tenho uma conta</Link>
            </div>
            <p className="mt-5 mb-3 text-body-secondary">&copy; Desenvolvido por Softbridge - 2024</p>
        </form>
    </div>;
}

export default NovaConta;