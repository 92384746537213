import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyDrwz07NnLREZMCEBe25mZFWA65ELmgCAo",
    authDomain: "softbridgeservicos.firebaseapp.com",
    projectId: "softbridgeservicos",
    storageBucket: "softbridgeservicos.appspot.com",
    messagingSenderId: "120913604995",
    appId: "1:120913604995:web:f0a9a6d9a855abdf5a06b6"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);