import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import firebase from '../Config/firebase';
import 'firebase/compat/auth';

import './resetarSenha.css';

function ReseteSenha() {

    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('');

    function recuperarSenha() {
        firebase.auth().sendPasswordResetEmail(email).then(resultado => {
            alert('E-mail enviado com sucesso!')
            setMensagem('');
            setSucesso('E-mail enviado com sucesso!')
        }).catch(erro => {
            setSucesso('');
            setMensagem('Erro ao enviar e-mail: ' + erro.message);
        })
    }

    return <div className='d-flex align-items-center text-center form-container' >
        <form className='form-login'>
            <Link to='/app'><img className="mb-4" src="/Images/softbridge_semFundo.png" alt="" width="300px" /></Link>
            <h1 className="h3 mb-3 fw-normal">Recuperar Senha</h1>

            <div className="form-floating">
                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="floatingInput" placeholder="email" />
                <label for="floatingInput">E-mail</label>
            </div>
            <p></p>
            <button onClick={recuperarSenha} className="btn btn-secondary w-100 py-2" type="button">Enviar</button>
            {mensagem.length > 0 ? <div className='alert alert-danger mt-2' role='alert'>{mensagem}</div> : ''}
            {sucesso.length > 0 ? <div className='alert alert-success mt-2' role='alert'>{sucesso}</div> : ''}
            <div className='login-links mt-5'>
                <Link to={"/app"} className='mx-3 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Login</Link>
            </div>
            <p className="mt-5 mb-3 text-body-secondary">&copy; Desenvolvido por Softbridge - 2024</p>
        </form>
    </div>;
}

export default ReseteSenha;